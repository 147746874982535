import {
  SERP_LOAD_MORE,
  SERP_PRODUCT_DETAIL_CLICK,
} from 'shared/experiments/consts'
import { event } from 'shared/experiments/utils/event'
import { isExperimentEnabled } from 'shared/experiments/utils/experiments'
import { bindDynamicEventsTracking } from 'shared/experiments/utils/tracking'

const handleSearchResults = () => {
  bindDynamicEventsTracking([
    {
      parentElementSelector:
        '[data-clientside-hook~="algoliaSearchListContainer"]',
      selector: '[data-clientside-hook~="productDetailButton"]',
      trackingEvent: event`${SERP_PRODUCT_DETAIL_CLICK} - Load more`,
    },
  ])
}

export default () => {
  if (isExperimentEnabled(SERP_LOAD_MORE)) {
    handleSearchResults()
  }
}
