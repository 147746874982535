import {
  AD_SERVER_SEARCH_PRODUCT,
  RETAIL_MEDIA_SEARCH_BOOSTER,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'

export const activateAdServerSearchBoosterExperiment = () => {
  const { isEnabled: searchExperimentEnabled } = getExperimentOnClient(
    AD_SERVER_SEARCH_PRODUCT
  )

  const { isEnabled: injectedBoosterExperimentEnabled } = getExperimentOnClient(
    RETAIL_MEDIA_SEARCH_BOOSTER
  )

  if (!searchExperimentEnabled || !injectedBoosterExperimentEnabled) {
    return
  }

  const searchTerms = [
    'erschöpfung',
    'gedächnis',
    'gedächtnis',
    'gedächtnisprobleme',
    'gegen müdigkeit',
    'konzentration',
    'leistungsfähigkeit',
    'müdigkeit',
    'ohrgeräusche',
    'ohr',
    'ohren',
    'ohren reinigen',
    'ohrenreinigen',
    'ohren reiniger',
    'ohrenreiniger',
    'ohren tropfen',
    'ohrentropfen',
    'ohrenentzündung',
    'ohrenreiniger',
    'ohrenreinigung',
    'ohrensalbe',
    'ohrenschmalz',
    'ohrenschmerzen',
    'ohrenspray',
    'ohrenstöpsel',
    'ohrkerzen',
    'ohrreiniger',
    'ohrreinigung',
    'ohrstöpsel',
    'ohrtropfen',
    'bläh',
    'blähbauch',
    'blähung',
    'blähungen',
    'gegenblähungen',
  ]

  const searchParams = new URLSearchParams(window.location.search)
  const query = searchParams.get('q')

  if (query) {
    const patternString = `\\b(${searchTerms.join('|')})\\b`
    const pattern = new RegExp(patternString, 'gi')
    const matches = query.match(pattern)

    if (matches) {
      void activateExperiment(RETAIL_MEDIA_SEARCH_BOOSTER)
    }
  }
}
