import store from 'shared/store'
import { ODS_MODAL_SEARCH_PAGE_BTN_ID } from 'views/consts'
import buildClientAPI from 'views/providers/clientAPI'

export default () => {
  const { odsService } = buildClientAPI()
  const {
    publicConfig: { onlineDoctorConfig },
  } = store.getPublicRuntimeConfig()
  let indicationLink
  const buttonOdsItem = document.querySelectorAll(
    `[data-clientside-hook~="ods-modal-trigger-buttonSERP"]`
  )

  buttonOdsItem.forEach(item => {
    indicationLink = onlineDoctorConfig.baseURI.toString()
    item.addEventListener('click', async function () {
      const buttonICD = document.getElementById('odsModalButtonSERP')
      const item = {
        partners: buttonICD.getAttribute('data-odspartners').split('-').pop(),
      }

      try {
        const treatmentName = JSON.parse(item.partners).find(
          partner => partner !== 'zava'
        )

        const odsResponse = await odsService.getOdsIndicationUrlByTreatmentName(
          treatmentName
        )

        indicationLink = odsResponse.split('//').pop()
      } catch (e) {
        indicationLink = onlineDoctorConfig.baseURI.toString()
      }

      /**
       * this is only used when page is Search Results page
       */
      const zavaButton = document.getElementById(ODS_MODAL_SEARCH_PAGE_BTN_ID)

      /**
       * since we now want to show transition modal for ods on search page,
       * we can change it directly to indicationLink and use it in OdsTransitionModal/index.tsx
       */
      zavaButton.setAttribute('href', indicationLink)
    })
  })
}
