import {
  EXPERIMENT_VARIATION,
  SERP_ADD_TO_CART_CLICK,
  SERP_NOW_PUSH,
  SERP_PRODUCT_DETAIL_CLICK,
} from 'shared/experiments/consts'
import { event } from 'shared/experiments/utils/event'
import { getExperimentOnClient } from 'shared/experiments/utils/experiments'
import trackEvent from 'shared/experiments/utils/trackOptimizelyEvent'

const serpNowPushExperimentHandler = () => {
  const serpContainer = document.querySelector<HTMLElement>('.o-SearchResults')
  const { isEnabled, variant } = getExperimentOnClient(SERP_NOW_PUSH)
  const experimentContainerEl = document.querySelector(
    '[data-experiment="cro-121"]'
  )

  if (!isEnabled || !serpContainer || !experimentContainerEl) {
    return
  }

  serpContainer?.addEventListener('click', e => {
    const element = <HTMLElement>e.target

    if (
      element.closest('[data-clientside-hook~="add2CartForm"] [type=submit]')
    ) {
      trackEvent(SERP_ADD_TO_CART_CLICK)
    } else if (
      element.closest('[data-clientside-hook~="productDetailButton"]')
    ) {
      trackEvent(SERP_PRODUCT_DETAIL_CLICK)
    } else if (
      element.closest('#now-refinement-control-checkbox') &&
      variant !== EXPERIMENT_VARIATION.V2
    ) {
      trackEvent(event`Now Filter Checkbox Clicked`)
    }
  })
}

export default serpNowPushExperimentHandler
